import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { createStore, select, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { TenantKey } from '../../shared/models/tenant';
import { PortalCommunicationService } from './portal-communication.service';
import { PortalMsalType } from './portal-configuration.service';

interface PortalProps {
  user?: AccountInfo;
  tenant?: TenantKey;
  msalType?: PortalMsalType
}

const portalStore = createStore(
  { name: 'user' },
  withProps<PortalProps>({ })
);

//save in local storage
export const persist = persistState(portalStore, {
  key: 'portal.store',
  storage: localStorageStrategy,
});

portalStore.subscribe((state) => console.log(state));
//persist.initialized$.subscribe(console.log);

@Injectable({ providedIn: 'root' })
export class PortalStore {

  store$ = portalStore;

  user$ = portalStore.pipe(select((state) => state.user));
  tenant$ = portalStore.pipe(select((state) => state.tenant));


  constructor(private communication: PortalCommunicationService) {
    this.communication.userLogout$.subscribe(() => {
      console.log('PortalStore.onUserLogout$ store cleaning');
      this.clean();
    });
  }

  get current(): PortalProps {
    return portalStore.getValue();
  }

  setUser(user?: AccountInfo) {
    portalStore.update((state) => ({
      ...state,
      user
    }));
  }

  setTenant(tenant: TenantKey | undefined) {
    portalStore.update((state) => ({
      ...state,
      tenant
    }));
  }

  setMsalType(msalType: PortalMsalType | undefined) {
    portalStore.update((state) => ({
      ...state,
      msalType
    }));
  }

  clean() {
    console.log("PortalStore.clean")
    //leave token
    portalStore.update((state) => ({
      ...state,
      user: undefined
      //msalType: undefined
    }));
    //npsStore.destroy();
  }
}
