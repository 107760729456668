export class ProjectSpokeGetListFilters {
  projectId: string | null = null;
  status: string | null = null;
  environment: string | null = null;

  pageSize: number = 20;
  continuationToken: string | null = null;

  constructor(init?: Partial<ProjectSpokeGetListFilters>) {
    Object.assign(this, init);
  }
  }
