import { SpokeNetworkDataDto } from "./partials/spoke-network-data.model";
import { SpokeReplicaRequestDetailsDto } from "./partials/spoke-replica-request-details.model";
import { SecondaryHubReferenceSimpleDto } from "./secondary-hub-reference.model";

export class SpokeReplicaCompleteDto {

  spokeId?: string = '';
  projectId?: string = '';
  secondaryHubRef?: SecondaryHubReferenceSimpleDto;
  spokeData?: SpokeNetworkDataDto;
  requestDetails?: SpokeReplicaRequestDetailsDto;

  constructor(partial?: Partial<SpokeReplicaCompleteDto>) {
    Object.assign(this, partial);
  }

}
