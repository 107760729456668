import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { catchError, lastValueFrom, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { ValidationRule } from '../../../../../shared/models/validation-rule';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { fillFormGroupValuesWithObject, formAddValidators } from '../../../../../shared/utils';
import { ProjectProfileService } from '../../../projects/api/project-profile.service';
import { ConfigurationService } from '../../../shared/api/configuration.service';
import { SpokeRequestNetworkDataApprovalExcerptDto } from '../../api/partials/spoke-request-network-data-approval-excerpt.model';
import { SecondaryHubReferenceSimpleDto } from '../../api/secondary-hub-reference.model';
import { CategoryDropdownOption, SecondaryHubsService } from '../../api/services/secondary-hub.service';
import { SpokeRequestsService } from '../../api/services/spoke-requests.service';
import { SpokeRequestApprovalDto } from '../../api/spoke-request-approval.model';
import { SpokeRequestCompleteDto } from '../../api/spoke-request-complete.model';

@Component({
  selector: 'app-spoke-request-approve',
  templateUrl: './spoke-request-approve.component.html'
})
export class SpokeRequestApproveComponent implements OnInit {

  mappingId?: string;
  spokeRequest?: SpokeRequestCompleteDto;

  formBlocked: boolean = false;

  spokeRequestForm?: FormGroup;
  secondaryHubReferenceForm?: FormGroup;
  spokeDataForm?: FormGroup;

  spokeRequestValidators: ValidationRule[] = [];
  secondaryHubReferenceValidators: ValidationRule[] = [];
  spokeDataValidators: ValidationRule[] = [];

  secondaryHubCategoriesOptions?: Observable<CategoryDropdownOption[]>;
  secondaryHubSubcategoriesOptions?: CategoryDropdownOption[];
  deploymentStageOptions?: Observable<string[]>;
  azureRegionOptions?: Observable<string[]>;
  spokeTopologyOptions: any[] = [];
  projectOptions?: Observable<string[]>;

  secondaryHubSubcategories?: Map<string, CategoryDropdownOption[]>;

  constructor(
    private formBuilder: FormBuilder,
    private spokeRequestsService: SpokeRequestsService,
    private secondaryHubService: SecondaryHubsService,
    private projectsService: ProjectProfileService,
    private notify: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private configurationService: ConfigurationService,
  ){ }

  async ngOnInit() {
    const spokeRequestApproval = new SpokeRequestApprovalDto();
    const secondaryHubReference = new SecondaryHubReferenceSimpleDto();
    const spokeData = new SpokeRequestNetworkDataApprovalExcerptDto();

    this.secondaryHubReferenceValidators = await lastValueFrom(this.spokeRequestsService.getValidators('OpSpokeRequestApprove'));
    this.spokeRequestForm = formAddValidators(this.formBuilder.group(spokeRequestApproval), this.spokeRequestValidators);

    this.spokeDataValidators = await lastValueFrom(this.spokeRequestsService.getValidators('OpSpokeRequestApprove', 'SecondaryHubReference'));
    this.secondaryHubReferenceForm = formAddValidators(this.formBuilder.group(secondaryHubReference), this.spokeDataValidators);

    this.spokeDataValidators = await lastValueFrom(this.spokeRequestsService.getValidators('OpSpokeRequestApprove', 'SpokeData'));
    this.spokeDataForm = formAddValidators(this.formBuilder.group(spokeData), this.spokeDataValidators);
    this.spokeDataForm.get('virtualNetworkCIDR')?.addAsyncValidators(this.virtualNetworkRemoteValidator.bind(this));

    this.deploymentStageOptions = this.configurationService.deploymentStages();
    this.azureRegionOptions = this.configurationService.azureRegionsForSpokes();
    this.spokeTopologyOptions = this.configurationService.spokeTopologies();

    this.secondaryHubCategoriesOptions = this.secondaryHubService.secondaryHubsCategoriesGet()
      .pipe(
        tap(categories => {
          this.secondaryHubSubcategories = new Map<string, CategoryDropdownOption[]>();
          categories
            .filter(cat => !!cat.categoryId)
            .forEach(cat =>
              this.secondaryHubSubcategories?.set(cat.categoryId!, Array.from(cat.subcategories!).map(subcat => new CategoryDropdownOption(subcat[0], subcat[1])))
          )
        }),
        map(categories => categories.filter(cat => !!cat.categoryId).map(category => new CategoryDropdownOption(category.categoryId!, category.categoryDescription) ))
    );

    // TODO SnP use 'Lazy Virtual Scroll' and search in dropdown to support large amount of projects
    this.projectOptions = this.projectsService.projectProfiles()
      .pipe(
        map(profiles => profiles.map(profile => profile.projectId))
      );

    this.route.params.pipe(
      switchMap(params => params['mappingId']
        ? this.spokeRequestsService.spokeRequestsGetById(params['mappingId'])
        : throwError(() => new Error('Expected parameter "mappingId" was empty'))
      ))
      .subscribe(value => {
        this.spokeRequest = value;
        this.mappingId = value.mappingId;
        this.refreshForms();
    });
  }

  async onNamingConventionChange() {
    const namingConventionCurrValue = this.spokeDataForm?.get('namingConvention')?.value;
    if (!namingConventionCurrValue || namingConventionCurrValue === '') {
      this.spokeDataForm?.controls['namingConvention'].setValue('spoke');
    }
  }

  virtualNetworkRemoteValidator(control: AbstractControl): Observable<{ [key: string]: string } | null> {
    if (!control.value) {
      return of(null);
    }
    return this.spokeRequestsService.spokeRequestValidateVirtualNetworkCidr(control.value, this.spokeRequest?.spokeId)
      .pipe(
        map((errors) => (errors.length ? { remoteConflict: errors.join(', ') } : null)),
        catchError(() => of(null))
      );
  }

  async onApproveClick() {
    if (this.mappingId) {
      this.formBlocked = true;

      const spokeRequest = <SpokeRequestApprovalDto> this.spokeRequestForm?.value;
      spokeRequest.secondaryHubRef = this.secondaryHubReferenceForm?.value;
      spokeRequest.spokeData = this.spokeDataForm?.value;
      spokeRequest.approvalState = "Approved";

      this.spokeRequestsService.SpokeApproveRequest(this.mappingId, spokeRequest)
      .subscribe({
        next: result => {
          if (result) {
            this.notify.success('Saved, redirecting ...')
            setTimeout(() => { this.navigateToSpokeRequests() }, 3000);
          } else {
            this.notify.error('Failed', result);
          }
        },
        error: e => this.formBlocked = false
      });

    }
    else {
      this.notify.error('"MappingID" property is empty, cancelling request');
    }
  }

  async onRejectClick() {
    if (this.mappingId) {
      this.formBlocked = true;

      const spokeRequest = <SpokeRequestApprovalDto> this.spokeRequestForm?.value;
      spokeRequest.approvalState = "Rejected";

      this.spokeRequestsService.SpokeApproveRequest(this.mappingId, spokeRequest)
      .subscribe({
        next: result => {
          if (result) {
            this.notify.success('Saved, redirecting ...')
            setTimeout(() => { this.navigateToSpokeRequests() }, 3000);
          } else {
            this.notify.error('Failed', result);
          }
        },
        error: e => this.formBlocked = false
      });

    }
    else {
      this.notify.error('"MappingID" property is empty, cancelling request');
    }
  }

  onSecondaryHubCategoryValueChange(event: any) {

    if (this.secondaryHubSubcategories
      && event?.value) {

      this.secondaryHubSubcategoriesOptions = this.secondaryHubSubcategories.get(event?.value);
    }
    else {
      this.secondaryHubSubcategoriesOptions = [];
    }
  }

  onReturnBackToSpokeRequestsClick() {
    this.navigateToSpokeRequests();
  }

  async navigateToSpokeRequests() {
    this.router.navigate(['/nps/project-spokes/requests/list']);
  }

  refreshForms() {
    if (this.spokeRequest && this.spokeRequestForm && this.secondaryHubReferenceForm && this.spokeDataForm) {
      fillFormGroupValuesWithObject(this.spokeRequestForm, this.spokeRequest);
      fillFormGroupValuesWithObject(this.spokeDataForm, this.spokeRequest);
    }
  }

  getErrorsFromControl(form: FormGroup, controlName: string) : string[] {
    const errors = form?.controls[controlName]?.errors;
    return errors ? Object.values(errors) : [];
  }

}
