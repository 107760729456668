<div class="flex mt-2 mb-4 justify-content-between">
  <div class="flex-row flex-3">
    <span class="text-4xl" *ngIf="isAdmin ?? false; else elsePageTitle">
      Project Spokes (admin)
      <!-- <sup class="p-badge p-badge-info">{{projectSpokes.length}}{{(filters.get('continuationToken')?.value?'+':'')}}</sup> -->
      <sup class="p-badge p-badge-info">{{projectSpokes.length}}{{(currContinuationToken ? '+' : '')}}</sup>
    </span>
    <ng-template #elsePageTitle>
      <span class="text-4xl">
        Project Spokes
        <!-- <sup class="p-badge p-badge-info">{{projectSpokes.length}}{{(filters.get('continuationToken')?.value?'+':'')}}</sup> -->
        <sup class="p-badge p-badge-info">{{projectSpokes.length}}{{(currContinuationToken ? '+' : '')}}</sup>
      </span>
    </ng-template>
    <p>Spokes Browser with focus on configuration and assignements description to particular Projects. Displays only Spokes for Projects permissioned for current user.</p>
  </div>
  <p-button class="flex-1 text-right" label="Create New Spoke Request" icon="pi pi-plus" styleClass="p-button-link" (click)="openSpokeRequestCreateDialog()"></p-button>
</div>

<div class="flex mt-2 mb-2 justify-content-around" [formGroup]="filters">
  <div class="flex-1 mr-2">
    <p-dropdown [options]="filterProjects"
    formControlName="projectId"
    [editable]="false"
    placeholder="Select Project..."
    [showClear]="true"
    appendTo="body"
    styleClass="w-full"
    inputStyleClass="w-full">s
  </p-dropdown>
</div>
<div class="flex-1 mr-2">
  <p-dropdown [options]="filterEnvironments"
  formControlName="environment"
  [editable]="false"
  placeholder="Select Environment..."
  [showClear]="true"
  appendTo="body"
  styleClass="w-full"
  inputStyleClass="w-full">
</p-dropdown>
</div>
<div class="flex-1 mr-2">
  <p-dropdown [options]="filterStatuses"
    formControlName="status"
    [editable]="false"
    placeholder="Select Status..."
    [showClear]="true"
    appendTo="body"
    styleClass="w-full"
    inputStyleClass="w-full">
  </p-dropdown>
</div>
  <div class="">
    <i pButton [rounded]="true" [text]="true" size="small" severity="secondary" icon="pi pi-filter-slash" (click)="filtersClear();"></i>
  </div>
</div>

<p-table #dt
  [value]="projectSpokes"
  [lazy]="true"
  [virtualScroll]="true"
  [virtualScrollItemSize]="tableItemHeight"
  [scrollable]="true"
  [scrollHeight]="tableScrollHeight"
  [rows]="tablePageSize"
  (onLazyLoad)="loadLazy($event)"
  lazyLoadOnInit="false"
  styleClass="p-datatable-sm w-full"
  [rowHover]="true">
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="spokeId">Spoke Id</th>
      <th pSortableColumn="projectId">Project Id</th>
      <th pSortableColumn="projectName">Project Name</th>
      <th pSortableColumn="tenantName">Tenant Name</th>
      <th pSortableColumn="status">Status</th>
      <th pSortableColumn="virtualNetworkCIDR">VNet</th>
      <th pSortableColumn="resourceGroupName">Resource Group Name</th>
      <th pSortableColumn="environment">Environment</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-i="rowIndex">
    <tr (dblclick)="openProjectSpokeDetailsDialog(item)" class="cursor-pointer" [style]="('height:'+tableItemHeight+'px')">
      <td>{{item.spokeId ?? 'n/a' }}</td>
      <td>{{item.projectId ?? 'n/a' }}</td>
      <td>{{item.projectName ?? 'n/a' }}</td>
      <td>{{item.tenantName ?? 'n/a' }}</td>
      <td>{{item.status ?? 'n/a' }}</td>
      <td>{{item.virtualNetworkCIDR ?? 'n/a' }}</td>
      <td>{{item.resourceGroupName ?? 'n/a' }}</td>
      <td>{{item.environment ?? 'n/a' }}</td>
      <td class="text-right">
        <p-button
          icon="pi pi-info-circle"
          styleClass="p-button-link"
          (click)="openProjectSpokeDetailsDialog(item)"
          pTooltip="Show Details"
          tooltipPosition="left"></p-button>
      </td>
    </tr>
  </ng-template>
</p-table>
<div class="flex align-items-center justify-content-center" *ngIf="projectSpokes?.length === 0" #elseEmptyList>
  <p class="py-8">The list is empty</p>
</div>
