import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RouterModule } from '@angular/router';
import { MsalModule } from '@azure/msal-angular';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { getBaseUrl } from '../main';
import { AppComponent } from './app.component';
import { NpsModule } from './nps/nps.module';
import { PortalModule } from './portal/portal.module';
import { AppRoutingModule } from './app-routing.module';
import { InsightsService } from './shared/services/insights.service';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
//import { TmsModule } from './tms/tms.module';
//import { AmsModule } from './ams/ams.module';
import { IamModule } from './iam/iam.module';
import { TmsModule } from './tms/tms.module';
import { AmsModule } from './ams/ams.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,

    PortalModule,
    NpsModule,
    TmsModule,
    AmsModule,
    IamModule,

    MsalModule,
    ToastModule,

    AppRoutingModule
  ],
  providers: [
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
    InsightsService,
    { provide: ErrorHandler, useClass: ApplicationinsightsAngularpluginErrorService },
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
