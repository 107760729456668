import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { distinctUntilChanged, filter, finalize, map, of, startWith, Subject, switchMap, tap } from 'rxjs';

import { isAdmin } from '../../../../../shared/auth/auth-utils';
import { LayoutService } from '../../../../../shared/services/layout.service';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { enumValues } from '../../../../../shared/utils';
import { ProjectProfileDto } from '../../../projects/api/project-profile.model';
import { ProjectProfileService } from '../../../projects/api/project-profile.service';
import { UserDetailsService } from '../../../shared/api/user-details.service';
import { ProjectSpokeSimpleDto } from '../../api/project-spoke-simple.model';
import { ProjectSpokesService } from '../../api/services/project-spokes.service';
import { SpokeDeploymentEnvironment, SpokeRequestStatus } from '../../api/spoke-enums';
import { ProjectSpokesDataComponent } from '../project-spokes-data/project-spokes-data.component';
import { SpokeRequestCreateComponent } from '../spoke-request-create/spoke-request-create.component';

@Component({
  selector: 'app-project-spokes-list',
  templateUrl: './project-spokes-list.component.html',
  providers: [ DialogService ],
})
export class ProjectSpokesListComponent implements OnInit, AfterViewInit {
  @ViewChild('dt') dt: Table | undefined;

  // a value defining if this view is drawn in context of admin or standard user
  isAdmin: boolean = false;

  filters: FormGroup;
  filterProjects: string[] = [];
  filterStatuses: string[] = enumValues(SpokeRequestStatus);
  filterEnvironments: string[] = enumValues(SpokeDeploymentEnvironment);
  filtersSnapshot?: ProjectSpokesFilter;

  currContinuationToken?: string;
  continuationToken$: Subject<string>;
  tableScrollHeight = '600px';
  tableItemHeight = 44;
  tablePageSize = 20;

  userProjects: ProjectProfileDto[] = [];
  projectSpokes: ProjectSpokeSimpleDto[] = [];

  hubDetailsDialog?: DynamicDialogRef;
  spokeRequestCreateNewDialog?: DynamicDialogRef;

  constructor(
    private projectsService: ProjectProfileService,
    private projectSpokesService: ProjectSpokesService,
    private dialogService: DialogService,
    private userService: UserDetailsService,
    private fb: FormBuilder,
    private layout: LayoutService,
    private notificationService: NotificationService,
  ) {
    this.filters = this.fb.group(new ProjectSpokesFilter({status: SpokeRequestStatus.New}))
    this.continuationToken$ = new Subject<string>();
   }

  async ngOnInit() {
    this. projectProfilesUpdater();
  }

  ngAfterViewInit() {
    // To prevent scrolling to the top
    this.updateScrollHeight();
    if (this.dt != null) {
      this.dt!.scroller!.autoSize = false;
    }

    this.projectSpokesUpdateListener();
  }

  projectProfilesUpdater() {
    this.userService.userMeGet()!
    .pipe(
      switchMap(user => {
        this.isAdmin = isAdmin(user);

        if (this.isAdmin)
          return this.projectsService.projectProfiles().pipe(map(x => x.map(y => y.projectId)));
        else
          return of(Object.keys(user.projects));
      })
    )
    .subscribe(next => {this.filterProjects = next});
  }

  projectSpokesUpdateListener() {

    this.filters.valueChanges
    .pipe(
      distinctUntilChanged((prev: ProjectSpokesFilter, curr: ProjectSpokesFilter) =>
        prev.projectId === curr.projectId
        && prev.status === curr.status
        && prev.environment === curr.environment),
      startWith(this.filters.value),
      tap(filters => {
        this.layout.startLoading();
        this.filtersSnapshot = filters;
      }),
      switchMap(filters => this.projectSpokesService.projectSpokesGetList(filters ?? {}, undefined, this.tablePageSize)),
      finalize(() => this.layout.stopLoading())
    )
    .subscribe({
      next: (response) => {
        this.layout.stopLoading();

        // filters changed, reset the table
        this.dt?.reset();
        this.dt?.scroller?.scrollToIndex(1);
        this.projectSpokes = response?.list ?? [];
        this.currContinuationToken = response.continuationToken ?? undefined;

        if (response.status === 206)
          this.notificationService.warning('The database query length was limited due to technical constraints - too many projects. Please contact support');
      }
    });

    this.continuationToken$
    .pipe(
      distinctUntilChanged((prev, curr) => prev === curr),
      filter(x => this.filtersSnapshot != null),
      tap(x => this.layout.startLoading()),
      switchMap((token) => this.projectSpokesService.projectSpokesGetList(this.filtersSnapshot, token, this.tablePageSize))
    )
    .subscribe({
      next: (response) => {
        this.layout.stopLoading();

        // if it's first page
        if (this.currContinuationToken == null) {
          this.projectSpokes = response?.list ?? [];
        }
        else {
          this.projectSpokes = response?.list ? [...this.projectSpokes,...response.list] : this.projectSpokes;
        }
        this.currContinuationToken = response.continuationToken ?? undefined;
      }
    });
  }

  loadLazy(event: TableLazyLoadEvent) {
    const {first, rows, last} = event;
    if (!first && !last) { return; }

    if (first! + rows! >= this.projectSpokes.length && this.currContinuationToken) {
      this.triggerRulesUpdate();
    }
  }

  triggerRulesUpdate() {
    if (this.currContinuationToken)
      this.continuationToken$.next(this.currContinuationToken);
  }

  filtersClear() {
    this.filters.patchValue(new ProjectSpokesFilter());
  }

  openProjectSpokeDetailsDialog(projectSpoke?: ProjectSpokeSimpleDto) {
    this.hubDetailsDialog = this.dialogService
      .open(ProjectSpokesDataComponent, {
        data: {
          projectId: projectSpoke?.projectId,
          spokeId: projectSpoke?.spokeId,
        },
        header: 'Project Spokes - Spoke Data',
        width: '800px',
        contentStyle: {"min-height": "800px", "background": "var(--surface-ground)"},
      });
  }

  openSpokeRequestCreateDialog() {
    this.spokeRequestCreateNewDialog = this.dialogService
      .open(SpokeRequestCreateComponent, {
        header: 'Spoke Request - Create New',
        width: '800px',
        contentStyle: {"min-height": "800px", "padding": "5%"},
      });

    this.spokeRequestCreateNewDialog.onClose
      .subscribe(() => {
        this.triggerRulesUpdate();
      });
  }

  // Listen to window resize event
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateScrollHeight();
  }

  updateScrollHeight() {
    // Set scrollHeight to 50% of the viewport height as an example
    const screenHeight = window.innerHeight;

    // todo, needs to optimize
    this.tableScrollHeight = `${Math.max(screenHeight - 245, 600)}px`; // minimum 600px

    const visibleRows = Math.ceil(window.innerHeight / this.tableItemHeight);
    this.tablePageSize = Math.max(visibleRows, 20); // Default to 20 if rows is not set
  }
}

export class ProjectSpokesFilter {
  projectId: string | null = null;
  status: string | null = null;
  environment: string | null = null;

  constructor(init?: Partial<ProjectSpokesFilter>) {
    Object.assign(this, init);
  }
}
