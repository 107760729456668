import { Component } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LayoutService } from '../../../../../shared/services/layout.service';
import { SecondaryHubSimpleDto } from '../../api/secondary-hub-simple.model';
import { SecondaryHubsService } from '../../api/services/secondary-hub.service';
import { ProjectSpokesSecondaryHubCreateComponent } from '../project-spokes-secondary-hub-create/project-spokes-secondary-hub-create.component';
import { ProjectSpokesSecondaryHubDataComponent } from '../project-spokes-secondary-hub-data/project-spokes-secondary-hub-data.component';

@Component({
  selector: 'app-project-spokes-secondary-hubs-list',
  templateUrl: './project-spokes-secondary-hubs-list.component.html',
  providers: [ DialogService ],
})
export class ProjectSpokesSecondaryHubsListComponent {

  filters: SecondaryHubsFilter;
  filterShubCategories: string[] = [];
  filterProjects: string[] = [];
  filterTenants: string[] = [];
  filterEnvironments: string[] = [];

  secondaryHubs: SecondaryHubSimpleDto[] = [];
  secondaryHubsFiltered: SecondaryHubSimpleDto[] = [];

  hubDetailsDialog?: DynamicDialogRef;

  constructor(
    private secondaryHubService: SecondaryHubsService,
    private dialogService: DialogService,
    private layout: LayoutService,
  ) {
    this.filters = new SecondaryHubsFilter();
  }

  async ngOnInit() {
    await this.refreshList();
  }

  async refreshList() {
    this.layout.startLoading();
    this.secondaryHubService.secondaryHubsGet()
      .subscribe({
        next: (response: SecondaryHubSimpleDto[]) => {
          this.secondaryHubs = response;
          this.refreshFilterLists(response);
          this.filterList();
          this.layout.stopLoading();
        },
        error: (error) => {
          this.layout.stopLoading();
        }
      });
  }

  // todo, fix other filters that do not use progressive scrolling using this format as an example
  refreshFilterLists(tableData: SecondaryHubSimpleDto[]) {
    this.filterShubCategories = [...new Set(tableData.map(q => q.categoryId))].filter(q => q !== null && q !== undefined) as string[];
    this.filterProjects = [...new Set(tableData.map(q => q.projectName))].filter(q => q !== null && q !== undefined) as string[];
    this.filterTenants = [...new Set(tableData.map(q => q.tenantName))].filter(q => q !== null && q !== undefined) as string[];
    this.filterEnvironments = [...new Set(tableData.map(q => q.environment))].filter(q => q !== null && q !== undefined) as string[];
  }

  filterList() {
      this.secondaryHubsFiltered = this.secondaryHubs.filter(q => {
        return (!this.filters.category || q.categoryId === this.filters.category) &&
               (!this.filters.project || q.projectName === this.filters.project) &&
               (!this.filters.tenant || q.tenantName === this.filters.tenant) &&
               (!this.filters.environment || q.environment === this.filters.environment);
      });
  }

  filtersClear() {
    this.filters.category = undefined;
    this.filters.project = undefined;
    this.filters.tenant = undefined;
    this.filters.environment = undefined;

    this.filterList();
  }

  openHubDetailsDialog(hub?: SecondaryHubSimpleDto) {
    this.hubDetailsDialog = this.dialogService
      .open(ProjectSpokesSecondaryHubDataComponent, {
        data: {
          categoryId: hub?.categoryId,
          subcategoryId: hub?.subcategoryId,
          secondaryHubId: hub?.secondaryHubId
        },
        header: 'Secondary Hub - Hub Data',
        width: '800px'
      });

  //dialog is read only we don't need to refresh the list
  //  this.hubDetailsDialog.onClose
  //    .subscribe(() => {
  //        this.refreshList();
  //    });
  }

  openCreateSecondaryHubDialog() {
    this.hubDetailsDialog = this.dialogService
      .open(ProjectSpokesSecondaryHubCreateComponent, {
        header: 'Secondary Hub - Create New',
        width: '800px'
      });

    this.hubDetailsDialog.onClose
      .subscribe(() => {
          this.refreshList();
      });
  }
}

class SecondaryHubsFilter {
  category?: string;
  project?: string;
  tenant?: string;
  environment?: string;
}

