

<div class="flex mt-2 mb-2 justify-content-between">
  <span class="text-4xl">Project Profiles <sup class="p-badge p-badge-info">{{projectsFiltered.length}}</sup></span>

  <p-button label="Create" icon="pi pi-plus" styleClass="p-button-link" (click)="openProjectDialog()"></p-button>
</div>

<div class="flex mt-2 mb-2 justify-content-around">
  <div class="flex-1 mr-2">
    <input type="text" pInputText
             [(ngModel)]="filters.projectId"
             (input)="refreshList()"
             placeholder="Project (search prefix)" appendTo="body" class="w-full" />
  </div>
  <div class="flex-1 mr-2">
    <p-dropdown [options]="filterTenants"
                [(ngModel)]="filters.tenant"
                (onChange)="refreshList()"
                (onClear)="filters.tenant = undefined; refreshList()"
                [editable]="false" placeholder="Tenant" [showClear]="true" appendTo="body" styleClass="w-full" inputStyleClass="w-full"></p-dropdown>
  </div>
  <div class="">
    <i pButton [rounded]="true" [text]="true" size="small" severity="secondary" icon="pi pi-times" (click)="filtersClear();"></i>
  </div>
</div>

<p-table [value]="projectsFiltered" styleClass="p-datatable-sm w-full"
         [rowHover]="true" sortField="name">
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="projectId">Id</th>
      <th pSortableColumn="projectName">Name</th>
      <th pSortableColumn="tenantName">Tenant</th>
      <th class="w-3"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-i="rowIndex">
    <tr (dblclick)="openProjectDialog(item.projectId)" class="cursor-pointer">
      <td>{{item.projectId}}</td>
      <td>{{item.projectName}}</td>
      <td>{{item.tenantName}}</td>
      <td class="text-right">
        <p-button icon="pi pi-users" styleClass="p-button-link" (click)="openUsersDialog(item.projectId, item.projectName)"></p-button>
        <p-button icon="pi pi-pencil" styleClass="p-button-link" (click)="openProjectDialog(item.projectId)"></p-button>
        <p-button icon="pi pi-times" styleClass="p-button-link" (click)="deleteProject(item.projectId)"></p-button>
      </td>
    </tr>
  </ng-template>
</p-table>
<p-confirmDialog></p-confirmDialog>

