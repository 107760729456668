import { UserMeDto, UserRoles } from "../../nps/features/shared/api/user-details.model";

export function isAdmin(user: UserMeDto) : boolean {

  if (user.roles.filter(x => x === UserRoles.Administrator || x === UserRoles.Operations).length > 0) { // isAdmin
    return true;
  }
  else {
      return false;
  }
}
