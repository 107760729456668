import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from './features/home/components/home.component';
import { IamGuard } from "./services/iam-auth.service";
import { IamLayoutLiteComponent } from "./features/layout/components/layout-lite.component";
import { IamLoginComponent } from "./features/home/components/login.component";
import { IamLogoutComponent } from "./features/home/components/logout.component";
import { IamLayoutComponent } from "./features/layout/components/layout.component";

const routes: Routes = [
    {
        path: '', component: HomeComponent, pathMatch: 'full'
    },
    {
        path: 'iam', component: IamLayoutComponent,
        canActivate: [IamGuard],
        children: [
          { path: '', component: HomeComponent, pathMatch: 'full' },
          { path: 'privileged-group', loadChildren: () => import('./features/privileged-group/privileged-group.module').then(m => m.PrivilegedGroupModule) },
        ]
    },
    {
        path: 'iam/login', component: IamLayoutLiteComponent,
        children: [
            { path: '', component: IamLoginComponent },
        ]
    },
    {
        path: 'iam/logout', component: IamLayoutLiteComponent,
        children: [
            { path: '', component: IamLogoutComponent },
        ]
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ],
    exports: [RouterModule]
})
export class IamRoutingModule { }
