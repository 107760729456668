import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PortalStore } from '../../../services/portal.store';
import { LayoutService } from '../../../../shared/services/layout.service';
import { portalEnvironment } from '../../../environments/portal-environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {

  isProduction = portalEnvironment.production;

  constructor(
    userStore: PortalStore,
    router: Router,
    layoutService: LayoutService
  ) {

    layoutService.config.dashboardBackground = true;

    //if user has no projects then we need to redirect him to the network request page

  //  const me = userStore.current.user;
  //  const networkRequestStart = '/network-requests/new';
  //  if (!me || Object.keys(me.projects).length == 0) {
  //    router.navigateByUrl(networkRequestStart);
  //    //console.log(networkRequestStart);
  //  }
  }
}
