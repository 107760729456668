import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { ValidationRule } from '../../../../../shared/models/validation-rule';
import { BaseApiService } from '../../../../../shared/services/_base-api.service';
import { NpsStore } from '../../../../services/nps.store';
import { NpsConfigurationApiUrl } from '../../../shared/api/configuration.service';
import { EgressDownloadOptions } from '../../components/project-spokes-data-download/egress-download-options.model';
import { IngressDownloadOptions } from '../../components/project-spokes-data-download/ingress-download-options.model';
import { ProjectSpokesFilter } from '../../components/project-spokes-list/project-spokes-list.component';
import { ProjectSpokeCompleteDto } from '../project-spoke-complete.model';
import { ProjectSpokeGetListFilters } from '../project-spoke-get-list-filters.model';
import { ProjectSpokeSimpleDto } from '../project-spoke-simple.model';

export const NpsProjectSpokesUrl = {
  ProjectSpokesGetList: () => `/api/projects/profiles/spokes`,
  ProjectSpokesGetListByProjectId: (projectId: string) => `/api/projects/profiles/${projectId}/spokes`,
  ProjectSpokeGetById: (projectId: string, spokeId: string) => `/api/projects/profiles/${projectId}/spokes/${spokeId}`,
  GpProjectSpokeLogsIngressGet: (projectId: string, spokeId: string) => `/api/projects/profiles/${projectId}/spokes/${spokeId}/logs/ingress`,
  GpProjectSpokeLogsEgressGet: (projectId: string, spokeId: string) => `/api/projects/profiles/${projectId}/spokes/${spokeId}/logs/egress`,
}

@Injectable({
  providedIn: 'root'
})
export class ProjectSpokesService extends BaseApiService {

  constructor(store: NpsStore) {
    super(store.apiUrl);
  }

  projectSpokesGetList(filters?: ProjectSpokesFilter, continuationToken?: string, pageSize?: number)
   : Observable<{list: ProjectSpokeSimpleDto[], continuationToken: string | null, status: number}> {

    const payload = Object.assign(new ProjectSpokeGetListFilters(), filters);
    if (continuationToken)
      payload.continuationToken = continuationToken;
    if(pageSize)
        payload.pageSize = pageSize;

    return super.postWithHeaders<ProjectSpokeGetListFilters,ProjectSpokeSimpleDto[]>(NpsProjectSpokesUrl.ProjectSpokesGetList(), payload)
    .pipe(
      map(response => ({ list: response.body, continuationToken: response.headers.get('X-Continuation-Token') ?? null, status: response.status })),
    );
  }

  projectSpokesGetListByProjectId(projectId: string) : Observable<ProjectSpokeSimpleDto[]> {
    return super.get<ProjectSpokeSimpleDto[]>(NpsProjectSpokesUrl.ProjectSpokesGetListByProjectId(projectId));
  }

  projectSpokeGetById(projectId: string, spokeId: string) : Observable<ProjectSpokeCompleteDto> {
    return super.get<ProjectSpokeCompleteDto>(NpsProjectSpokesUrl.ProjectSpokeGetById(projectId, spokeId));
  }

  spokeMonitoringLogsDownloadIngress(projectId: string, spokeId: string, options: IngressDownloadOptions) : Observable<Blob> {
    return super.postWithBlobResponse(NpsProjectSpokesUrl.GpProjectSpokeLogsIngressGet(projectId, spokeId), options);
  }

  spokeMonitoringLogsDownloadEgress(projectId: string, spokeId: string, options: EgressDownloadOptions) : Observable<any> {
    return super.postWithBlobResponse(NpsProjectSpokesUrl.GpProjectSpokeLogsEgressGet(projectId, spokeId), options);
  }

  getValidators(method: keyof typeof NpsProjectSpokesUrl, part: string = '') {
    return super.get<ValidationRule[]>(NpsConfigurationApiUrl.ConfigurationValidators(), { method, part });
  }
}
