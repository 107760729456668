import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ProjectSpokesDataDownloadComponent } from './components/project-spokes-data-download/project-spokes-data-download.component';
import { ProjectSpokesDataListingComponent } from './components/project-spokes-data-listing/project-spokes-data-listing.component';
import { ProjectSpokesDataComponent } from './components/project-spokes-data/project-spokes-data.component';
import { ProjectSpokesListComponent } from './components/project-spokes-list/project-spokes-list.component';
import { ProjectSpokesPrimaryHubCreateComponent } from './components/project-spokes-primary-hub-create/project-spokes-primary-hub-create.component';
import { ProjectSpokesPrimaryHubDataComponent } from './components/project-spokes-primary-hub-data/project-spokes-primary-hub-data.component';
import { ProjectSpokesPrimaryHubsComponentList } from './components/project-spokes-primary-hubs-list/project-spokes-primary-hubs-list.component';
import { ProjectSpokesSecondaryHubCreateComponent } from './components/project-spokes-secondary-hub-create/project-spokes-secondary-hub-create.component';
import { ProjectSpokesSecondaryHubDataComponent } from './components/project-spokes-secondary-hub-data/project-spokes-secondary-hub-data.component';
import { ProjectSpokesSecondaryHubsListComponent } from './components/project-spokes-secondary-hubs-list/project-spokes-secondary-hubs-list.component';
import { ProjectSpokesSpokeReplicateComponent } from './components/project-spokes-spoke-replicate/project-spokes-spoke-replicate.component';
import { SpokeRequestApproveComponent } from './components/spoke-request-approve/spoke-request-approve.component';
import { SpokeRequestCreateComponent } from './components/spoke-request-create/spoke-request-create.component';
import { SpokeRequestsDataComponent } from './components/spoke-requests-data/spoke-requests-data.component';
import { SpokeRequestsListComponent } from './components/spoke-requests-list/spoke-requests-list.component';
import { ProjectSpokesRoutingModule } from './project-spokes-routing.module';

@NgModule({
  declarations: [
    ProjectSpokesPrimaryHubsComponentList,
    ProjectSpokesPrimaryHubDataComponent,
    ProjectSpokesPrimaryHubCreateComponent,
    ProjectSpokesSecondaryHubsListComponent,
    ProjectSpokesSecondaryHubDataComponent,
    ProjectSpokesSecondaryHubCreateComponent,
    ProjectSpokesListComponent,
    ProjectSpokesDataComponent,
    SpokeRequestsListComponent,
    SpokeRequestsDataComponent,
    ProjectSpokesSpokeReplicateComponent,
    SpokeRequestCreateComponent,
    SpokeRequestApproveComponent,
    ProjectSpokesDataListingComponent,
    ProjectSpokesDataDownloadComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ProjectSpokesRoutingModule,
    TableModule,
    DropdownModule,
    DynamicDialogModule,
    ButtonModule,
    InputTextModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    InputSwitchModule,
    PanelModule,
    CardModule,
    TooltipModule,
    TabViewModule,
    SelectButtonModule,
    RadioButtonModule,
    ConfirmDialogModule
  ]
})
export class ProjectSpokesModule { }
