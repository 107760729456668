import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProjectSpokesListComponent } from './components/project-spokes-list/project-spokes-list.component';
import { ProjectSpokesPrimaryHubsComponentList } from './components/project-spokes-primary-hubs-list/project-spokes-primary-hubs-list.component';
import { ProjectSpokesSecondaryHubsListComponent } from './components/project-spokes-secondary-hubs-list/project-spokes-secondary-hubs-list.component';
import { SpokeRequestCreateComponent } from './components/spoke-request-create/spoke-request-create.component';
import { SpokeRequestsListComponent } from './components/spoke-requests-list/spoke-requests-list.component';

const routes: Routes = [
  { path: '', /*canActivate: [MsalGuard],*/ redirectTo: 'list', pathMatch: 'full' },
  { path: 'hubs/primary-hubs/list', /*canActivate: [MsalGuard],*/ component: ProjectSpokesPrimaryHubsComponentList },
  { path: 'hubs/secondary-hubs/list', /*canActivate: [MsalGuard],*/ component: ProjectSpokesSecondaryHubsListComponent },
  { path: 'list', /*canActivate: [MsalGuard],*/ component: ProjectSpokesListComponent },
  { path: 'requests/list', /*canActivate: [MsalGuard],*/ component: SpokeRequestsListComponent },
  { path: 'requests/create', /*canActivate: [MsalGuard],*/ component: SpokeRequestCreateComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectSpokesRoutingModule { }
