// todo snp, use them in actual models
export enum SpokeDeploymentEnvironment {
  Development = "Development",
  QA = "QA",
  Staging = "Staging",
  Production = "Production"
}

// todo snp, use them in actual models
export enum SpokeRequestStatus {
  New = "New",
  Approved = "Approved",
  Rejected = "Rejected",
  Running = "Running",
  Failed = "Failed",
  Success = "Success",
  ManuallyAdded = "ManuallyAdded"
}
